import React, { useContext } from "react"
import styled from "styled-components"
import { Icon } from "rsuite"
import { NotificationManager } from "react-notifications"
import { useLazyQuery } from "@apollo/client"

import { MOBILE, LANDSCAPE, TAB } from "../../constants/breakpoints"
import {
  COLOR_SET_FAVORITE_FALSE,
  COLOR_SET_FAVORITE_TRUE,
} from "../../constants/colors"
import {
  DATA_TYPE_STATION,
  DATA_TYPE_ARTIST,
  DATA_TYPE_ARTIST_PODCAST,
  DATA_TYPE_ARTIST_VIDEO,
  DATA_TYPE_STATION_PODCAST,
  DATA_TYPE_STATION_VIDEO,
} from "../../constants/props"

import { QUERY_PAGE_DATA } from "../../queries/page/user"

import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share"
import { CopyToClipboard } from "react-copy-to-clipboard"
import copy from "copy-to-clipboard"
import iconFacebook from "../../images/social/fb.png"
import iconTwitter from "../../images/social/twt.png"
import iconWhatsapp from "../../images/social/whatsapp.png"
import iconStreamafrica from "../../images/social/streamafrica.png"
import iconHtmlUrl from "../../images/html-url.png"
import closeShare from "../../images/social/cross-icon.png"
import { AppContext } from "../../contexts/AppContext"
import { useMutation } from "@apollo/client"
import {
  MUTATION_ADD_LIKE,
  MUTATION_DELETE_LIKE,
} from "../../queries/page/user"
import auth from "../../auth/auth"
import {
  PROP_FONT_PRIMARY
} from "../../constants/props"

const Section = styled.div`
  width: auto;
  position: relative;
`
const ShareHeading = styled.h3`
  font-family: ${PROP_FONT_PRIMARY};
  color: #002b3c;
  font-size: 14px;
  @media (min-width: ${TAB.minWidth}px) {
    font-size: initial;
  }
`
const SocialDropdown = styled.div`
  width: ${MOBILE.social.width}vw;
  min-height: 100px;
  background: #dfdfdf;
  position: ${props => props.shareBoxArrange ? "relative" : "absolute"};
  z-index: 9999;
  padding: 5px;
  border-radius: 5px;
  font-weight: bold;
  ${props => {
    switch (props.position[0]) {
      case "top":
        return props.popupShareBox ? "bottom: 30px;" : "bottom: 20px;"
      case "bottom":
        return "top: 20px"
      case "left":
        return "right:20px"
      case "right":
        return "left: 20px"
    }
  }}

  ${props => {
    switch (props.position[1]) {
      case "left":
        return "right: 0px;"
      case "right":
        return "left: 0px"
      case "middle":
        return props.position[0] == "top" ? "left:-90px" : "bottom:-55px;"
    }
  }}

  & img {
    width: 15px;
    margin: 1px 0px;
    display: inherit !important;
  }
  & button {
    width: 100%;
    text-align: left;
    font-size: 10px !important;
    color: #002b3c !important;
    &:focus {
      outline: none;
    }
    &:hover {
      color: #73767c !important;
    }
  }

  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.social.width}px;
    padding: 10px;
    & img {
      width: 20px;
      margin: 3px 0px;
      display: inherit !important;
    }
    & button {
      width: 100%;
      text-align: left;
      font-size: 10px !important;
      color: #002b3c !important;
      &:focus {
        outline: none;
      }
      &:hover {
        color: #73767c !important;
      }
    }
  }
`
const LikeButton = styled(Icon)`
  font-size: ${props => props.shareBoxArrange === false ? "small" : MOBILE.box.iconSize+"px"};
  cursor: pointer;
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    font-size: ${props => props.shareBoxArrange === false ? "small" : LANDSCAPE.box.iconSize+"px"};
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${props => props.shareBoxArrange === false ? "small" : TAB.box.iconSize+"px"};
  }
`

const ShareButton = styled(Icon).attrs({
  icon: "share-alt",
})`
  cursor: pointer;
  font-size: ${props => props.shareBoxArrange === false ? "small" : MOBILE.box.iconSize+"px"}
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    font-size: ${props => props.shareBoxArrange === false ? "small" : LANDSCAPE.box.iconSize+"px"}
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${props => props.shareBoxArrange === false ? "small" : TAB.box.iconSize+"px"}
  }
  padding: 0px 0px 0px 5px;
  color: ${COLOR_SET_FAVORITE_FALSE};
`

const ClipBoard = styled.div`
  display: flex;
  flex: 1;
  cursor: pointer;
  & button {
    background-color: transparent;
    border: none;
    padding: 0px;
    font: inherit;
    color: inherit;
    cursor: pointer;
    font-size: 10px;
    margin-top: 5px;
    @media (min-width: ${TAB.minWidth}px) {
      margin-top: 0px;
    }
  }
`
const CopyIframe = styled.div`
display: flex;
flex: 1;
cursor: pointer;
& button {
  background-color: transparent;
  border: none;
  padding: 0px;
  font: inherit;
  color: inherit;
  cursor: pointer;
  font-size: 10px;
  margin-top: 5px;
  @media (min-width: ${TAB.minWidth}px) {
    margin-top: 0px;
  }
}
`

const CrossIcon = styled.div`
  position: absolute;
  width: auto;
  top: 0px;
  right: 2px;
  cursor: pointer;
`

const frontDomain = `${process.env.GATSBY_SERVER_URL}`
const embedStartTag = `<iframe style="width:100%; height:100px;" scrolling="no" frameborder="no" src="${process.env.GATSBY_SERVER_URL}/embededplayer?eid=`
const embedEndTag = `"></iframe>`

const getLikeRecord = (userLikes, likeType, id) => {
  if (userLikes) {
    switch (likeType) {
      case DATA_TYPE_STATION:
        return userLikes.station.length
          ? userLikes.station.find(s => s.likeID === id)
          : null
      case DATA_TYPE_ARTIST:
        return userLikes.artist.length
          ? userLikes.artist.find(a => a.likeID === id)
          : null
      case DATA_TYPE_STATION_PODCAST:
        return userLikes.stationPodcast.length
          ? userLikes.stationPodcast.find(s => s.likeID === id)
          : null
      case DATA_TYPE_STATION_VIDEO:
        return userLikes.stationVideo.length
          ? userLikes.stationVideo.find(s => s.likeID === id)
          : null

      case DATA_TYPE_ARTIST_PODCAST:
        return userLikes.artistPodcast.length
          ? userLikes.artistPodcast.find(a => a.likeID === id)
          : null

      case DATA_TYPE_ARTIST_VIDEO:
        return userLikes.artistVideo.length
          ? userLikes.artistVideo.find(a => a.likeID === id)
          : null
    }
  }
  return null
}

const removeLikeRecord = (userLikes, likeType, likeRecord) => {
  if (userLikes && likeRecord) {
    let newUserLikes = { ...userLikes }
    switch (likeType) {
      case DATA_TYPE_STATION:
        if (newUserLikes.station.length)
          newUserLikes.station.splice(
            newUserLikes.station.findIndex(s => s.id === likeRecord.id),
            1
          )
        break
      case DATA_TYPE_ARTIST:
        if (newUserLikes.artist.length)
          newUserLikes.artist.splice(
            newUserLikes.artist.findIndex(a => a.id === likeRecord.id),
            1
          )
        break
      case DATA_TYPE_STATION_PODCAST:
        if (newUserLikes.stationPodcast.length)
          newUserLikes.stationPodcast.splice(
            newUserLikes.stationPodcast.findIndex(s => s.id === likeRecord.id),
            1
          )
        break
      case DATA_TYPE_STATION_VIDEO:
        if (newUserLikes.stationVideo.length)
          newUserLikes.stationVideo.splice(
            newUserLikes.stationVideo.findIndex(s => s.id === likeRecord.id),
            1
          )
        break
      case DATA_TYPE_ARTIST_PODCAST:
        if (newUserLikes.artistPodcast.length)
          newUserLikes.artistPodcast.splice(
            newUserLikes.artistPodcast.findIndex(a => a.id === likeRecord.id),
            1
          )
        break
      case DATA_TYPE_ARTIST_VIDEO:
        if (newUserLikes.artistVideo.length)
          newUserLikes.artistVideo.splice(
            newUserLikes.artistVideo.findIndex(a => a.id === likeRecord.id),
            1
          )
        break
    }
    return newUserLikes
  }

  return null
}

const addLikeRecord = (userLikes, likeType, addResponse) => {
  if (
    userLikes &&
    addResponse &&
    addResponse.data &&
    addResponse.data.createUserLike
  ) {
    let addData = addResponse.data.createUserLike
    let newUserLikes = { ...userLikes }
    switch (likeType) {
      case DATA_TYPE_STATION:
        if (
          addData.userLike &&
          addData.userLike.id &&
          addData.userLike.station &&
          addData.userLike.station.id
        )
          newUserLikes.station.push({
            id: addData.userLike.id,
            likeID: addData.userLike.station.id,
            data: addData.userLike.station,
          })
        break
      case DATA_TYPE_ARTIST:
        if (
          addData.userLike &&
          addData.userLike.id &&
          addData.userLike.artist &&
          addData.userLike.artist.id
        )
          newUserLikes.artist.push({
            id: addData.userLike.id,
            likeID: addData.userLike.artist.id,
            data: addData.userLike.artist,
          })
        break
      case DATA_TYPE_STATION_PODCAST:
        if (
          addData.userLike &&
          addData.userLike.id &&
          addData.userLike.mediacontent &&
          addData.userLike.mediacontent.id
        )
          newUserLikes.stationPodcast.push({
            id: addData.userLike.id,
            likeID: addData.userLike.mediacontent.id,
            data: addData.userLike.mediacontent,
          })
        break
      case DATA_TYPE_STATION_VIDEO:
        if (
          addData.userLike &&
          addData.userLike.id &&
          addData.userLike.mediacontent &&
          addData.userLike.mediacontent.id
        )
          newUserLikes.stationVideo.push({
            id: addData.userLike.id,
            likeID: addData.userLike.mediacontent.id,
            data: addData.userLike.mediacontent,
          })
        break
      case DATA_TYPE_ARTIST_PODCAST:
        if (
          addData.userLike &&
          addData.userLike.id &&
          addData.userLike.mediacontent &&
          addData.userLike.mediacontent.id
        )
          newUserLikes.artistPodcast.push({
            id: addData.userLike.id,
            likeID: addData.userLike.mediacontent.id,
            data: addData.userLike.mediacontent,
          })
        break
      case DATA_TYPE_ARTIST_VIDEO:
        if (
          addData.userLike &&
          addData.userLike.id &&
          addData.userLike.mediacontent &&
          addData.userLike.mediacontent.id
        )
          newUserLikes.artistVideo.push({
            id: addData.userLike.id,
            likeID: addData.userLike.mediacontent.id,
            data: addData.userLike.mediacontent,
          })
        break
    }
    return newUserLikes
  }

  return null
}

const LikeAndShare = ({
  id,
  shareID,
  shareURL,
  shareBoxArrange,
  popupShareBox,
  position = ["top", "left"],
  likeType,
  copyEmbedCode,
}) => {
  const {
    showShare,
    setShowShare,
    userState,
    userLikes,
    setLikesData,
  } = useContext(AppContext)
  const [addUserLike] = useMutation(MUTATION_ADD_LIKE)
  const [deleteUserLike] = useMutation(MUTATION_DELETE_LIKE)

  let isLiked = false
  let likeRecord = getLikeRecord(userLikes, likeType, id)

  const [getPageData] = useLazyQuery(QUERY_PAGE_DATA, {
    fetchPolicy: "network-only",
    onCompleted: pageData => {
      console.log("[PAGE-DATA] page data is ", pageData)
      if (pageData && pageData.pages && pageData.pages.length) {
        copy(frontDomain + "/" + pageData.pages[0].identifier)
      } else {
        copy(frontDomain + shareURL)
      }
      NotificationManager.success("URL Copied!")
    },
  })

  if (likeRecord) isLiked = true

  const toggleLike = (id, likeType, isLiked) => {
    if (userState.isLoggedIn) {
      if (!isLiked) {
        addUserLike({
          variables: {
            input: {
              data: {
                user: userState.id,
                type: likeType,
                mediacontent:
                  likeType !== DATA_TYPE_STATION &&
                    likeType !== DATA_TYPE_ARTIST
                    ? id
                    : undefined,
                station: likeType === DATA_TYPE_STATION ? id : undefined,
                artist: likeType === DATA_TYPE_ARTIST ? id : undefined,
              },
            },
          },
          context: {
            headers: {
              Authorization: "Bearer " + auth.getToken(),
            },
          },
        }).then(response => {
          setLikesData(addLikeRecord(userLikes, likeType, response))
        })
      } else {
        deleteUserLike({
          variables: {
            input: {
              where: {
                id: likeRecord.id,
              },
            },
          },
          context: {
            headers: {
              Authorization: "Bearer " + auth.getToken(),
            },
          },
        }).then(() => {
          setLikesData(removeLikeRecord(userLikes, likeType, likeRecord))
        })
      }
    } else {
      NotificationManager.info("Please login to like!")
    }
  }

  return (
    <Section>
      <LikeButton shareBoxArrange={shareBoxArrange}
        icon={isLiked ? "heart" : "heart-o"}
        onClick={() => {
          toggleLike(id, likeType, isLiked)
        }}
        style={{
          color: isLiked ? COLOR_SET_FAVORITE_TRUE : COLOR_SET_FAVORITE_FALSE,
        }}
      />
      <ShareButton shareBoxArrange={shareBoxArrange} onClick={() => setShowShare(shareID)} />
      {showShare === shareID && (
        <SocialDropdown position={position} shareBoxArrange={shareBoxArrange} popupShareBox={popupShareBox} id={id}>
          <ShareHeading>SHARE</ShareHeading>
          <FacebookShareButton
            url={frontDomain + shareURL}
            onClick={() => {
              setShowShare(null)
            }}
          >
            <img src={iconFacebook} style={{ marginLeft: 0 }} /> <span>Facebook</span>
          </FacebookShareButton>
          <TwitterShareButton
            url={frontDomain + shareURL}
            onClick={() => {
              setShowShare(null)
            }}
          >
            <img src={iconTwitter} style={{ marginLeft: 0 }} /> <span>Twitter</span>
          </TwitterShareButton>
          <WhatsappShareButton
            url={frontDomain + shareURL}
            onClick={() => {
              setShowShare(null)
            }}
          >
            <img src={iconWhatsapp} style={{ marginLeft: 0 }} /> <span>Whatsapp</span>
          </WhatsappShareButton>
          <ClipBoard
            onClick={() => {
              if (likeType === DATA_TYPE_STATION) {
                // try to find if there is any spefic url for this station
                getPageData({
                  variables: {
                    where: {
                      station: id,
                    },
                  },
                })
              } else if (likeType === DATA_TYPE_ARTIST) {
                getPageData({
                  variables: {
                    where: {
                      artist: id,
                    },
                  },
                })
              } else {
                copy(frontDomain + shareURL)
                NotificationManager.success("URL Copied!")
              }

              setShowShare(null)
            }}
          >
            <button className="react-share__ShareButton">
              <span>
                <img src={iconStreamafrica} style={{ marginLeft: 0 }} />
                &nbsp;Copy Stream Africa Station URL
              </span>
            </button>
          </ClipBoard>
          {copyEmbedCode && <CopyIframe onClick={() => {
            copy(embedStartTag + id + embedEndTag)
            NotificationManager.success("Code copied!")
          }}>
            <button className="react-share__ShareButton">
              <span>
                <img src={iconHtmlUrl} style={{ marginLeft: 0 }} />
                &nbsp;Copy Embed Code
              </span>
            </button>
          </CopyIframe>}
          <CrossIcon onClick={() => setShowShare(null)}>
            <img src={closeShare} />
          </CrossIcon>
        </SocialDropdown>
      )}
    </Section>
  )
}

export default LikeAndShare
