import gql from "graphql-tag"

export const QUERY_ALL_STATIONS = gql`
  query QueryAllStations(
    $offset: Int
    $max: Int
    $order: String
    $where: JSON
  ) {
    stations(start: $offset, limit: $max, sort: $order, where: $where) {
      id
      title
      streamuri
      country {
        id
        title
        Timezone
      }
      image {
        id
        url
      }
      about
      premium
      featured
      available
      presenters(limit: 1) {
        id
        title
      }
      slug
    }
  }
`

// Desc: Gives Station Data on providing ID
export const QUERY_ALL_LIVE_STATIONS = gql`
  query AllLiveStations($offset: Int, $max: Int, $order: String) {
    stations(
      start: $offset
      limit: $max
      sort: $order
      where: { active: true }
    ) {
      id
      title
      streamuri
      image {
        id
        url
      }
      country {
        id
        title
      }
      about
      premium
    }
  }
`

export const QUERY_STATION_BY_ID_AND_VIDEOS = gql`
  query StationAndItsVideos($id: ID!) {
    station(id: $id) {
      id
      title
      videos: mediacontents(
        sort: "title:asc"
        where: { contenttype: "video", station: $id }
      ) {
        id
        title
        about
        uri
        viewed
        premium
        image {
          id
          url
        }
        station {
          id
          title
        }
        updatedAt
      }
    }
  }
`
export const QUERY_STATION_BY_ID_NAME_ONLY = gql`
  query Station($id: ID!) {
    station(id: $id) {
      id
      title
    }
  }
`

export const QUERY_STATION_BY_ID = gql`
  query Station($id: ID!) {
    station(id: $id) {
      id
      title
      streamuri
      location
      image {
        id
        url
      }
      country {
        id
        title
      }
      about
      broadcastfreqs {
        id
        origin
        frequency
      }
      contact
      premium
      available
      presenters: presenters {
        id
        title
        image {
          id
          url
        }
        shows {
          id
          title
          episodes {
            day
            from
            to
          }
        }
      }
      schedule: shows {
        id
        mon: episodes(where: { day: "Monday" }) {
          id
          day
          from
          to
          show {
            id
            title
          }
        }
        tue: episodes(where: { day: "Tuesday" }) {
          id
          day
          from
          to
          show {
            id
            title
          }
        }
        wed: episodes(where: { day: "Wednesday" }) {
          id
          day
          from
          to
          show {
            id
            title
          }
        }
        thu: episodes(where: { day: "Thursday" }) {
          id
          day
          from
          to
          show {
            id
            title
          }
        }
        fri: episodes(where: { day: "Friday" }) {
          id
          day
          from
          to
          show {
            id
            title
          }
        }
        sat: episodes(where: { day: "Saturday" }) {
          id
          day
          from
          to
          show {
            id
            title
          }
        }
        sun: episodes(where: { day: "Sunday" }) {
          id
          day
          from
          to
          show {
            id
            title
          }
        }
      }
      podcasts: mediacontents(
        sort: "updatedAt:desc"
        where: { contenttype: "audio", station: { id: $id } }
      ) {
        id
        title
        about
        uri
        premium
        viewed
        image {
          id
          url
        }
        station {
          id
          title
        }
      }
      videos: mediacontents(
        sort: "updatedAt:desc"
        where: { contenttype: "video", station: { id: $id } }
      ) {
        id
        title
        about
        uri
        viewed
        premium
        image {
          id
          url
        }
        station {
          id
          title
        }
        updatedAt
      }
    }
  }
`

export const QUERY_STATIONS_BY_SLUG = gql`
  query Stations($slug: String) {
    stations(where: { slug: $slug }) {
      id
      title
      streamuri
      location
      slug
      image {
        id
        url
      }
      country {
        id
        title
      }
      about
      broadcastfreqs {
        id
        origin
        frequency
      }
      contact
      premium
      available
      presenters: presenters {
        id
        title
        image {
          id
          url
        }
        shows {
          id
          title
          episodes {
            day
            from
            to
          }
        }
      }
      schedule: shows {
        id
        mon: episodes(where: { day: "Monday" }) {
          id
          day
          from
          to
          show {
            id
            title
          }
        }
        tue: episodes(where: { day: "Tuesday" }) {
          id
          day
          from
          to
          show {
            id
            title
          }
        }
        wed: episodes(where: { day: "Wednesday" }) {
          id
          day
          from
          to
          show {
            id
            title
          }
        }
        thu: episodes(where: { day: "Thursday" }) {
          id
          day
          from
          to
          show {
            id
            title
          }
        }
        fri: episodes(where: { day: "Friday" }) {
          id
          day
          from
          to
          show {
            id
            title
          }
        }
        sat: episodes(where: { day: "Saturday" }) {
          id
          day
          from
          to
          show {
            id
            title
          }
        }
        sun: episodes(where: { day: "Sunday" }) {
          id
          day
          from
          to
          show {
            id
            title
          }
        }
      }
      podcasts: mediacontents(
        sort: "updatedAt:desc"
        where: { contenttype: "audio" }
      ) {
        id
        title
        about
        uri
        premium
        viewed
        image {
          id
          url
        }
        station {
          id
          title
        }
      }
      videos: mediacontents(
        sort: "updatedAt:desc"
        where: { contenttype: "video" }
      ) {
        id
        title
        about
        uri
        viewed
        premium
        image {
          id
          url
        }
        station {
          id
          title
        }
        updatedAt
      }
    }
  }
`

export const QUERY_FEATURED_STATIONS_BY_COUNTRY_ID = gql`
  query FeaturedStationsByCountryId($offset: Int, $max: Int, $id: ID!) {
    stations(
      start: $offset
      limit: $max
      where: { country_in: $id, featured: true }
    ) {
      id
      title
      streamuri
      country {
        id
        title
      }
      image {
        id
        url
      }
      about
      premium
      featured
      presenters {
        id
        title
      }
      shows {
        id
        title
      }
    }
  }
`
export const QUERY_ALL_STATIONS_VIDEOS = gql`
  query AllVideosByStation($offset: Int, $max: Int, $order: String) {
    stations(
      start: $offset
      limit: $max
      sort: $order
      where: { mediacontents: { contenttype: "video" } }
    ) {
      id
      title
      videos: mediacontents(
        sort: "updatedAt:desc"
        where: { contenttype: "video" }
      ) {
        id
        title
        uri
        contenttype
        viewed
        image {
          id
          url
        }
        artist {
          id
          title
        }
        presenter {
          id
          title
        }
        station {
          id
          title
        }
        premium
        updatedAt
      }
    }
  }
`

export const QUERY_STATION_BY_ID_BASIC_DETAILS = gql`
  query Station($id: ID!) {
    station(id: $id) {
      id
      title
      streamuri
      location
      about
      contact
      broadcastfreqs {
        id
        origin
        frequency
      }
      country {
        id
        title
      }
      image {
        id
        url
      }
    }
  }
`

export const MUTATION_UPDATE_STATION = gql`
  mutation UpdateStation($input: updateStationInput) {
    updateStation(input: $input) {
      station {
        id
        title
        streamuri
        location
        image {
          id
          url
        }
        about
        contact
        country {
          id
        }
      }
    }
  }
`
export const MUTATION_ADD_BROADCASTFREQS = gql`
  mutation AddFrequency($input: createBroadcastfreqInput) {
    createBroadcastfreq(input: $input) {
      broadcastfreq {
        id
      }
    }
  }
`
export const MUTATION_DELETE_BROADCASTFREQS = gql`
  mutation DeleteFrequency($input: deleteBroadcastfreqInput) {
    deleteBroadcastfreq(input: $input) {
      broadcastfreq {
        id
      }
    }
  }
`
