import React, { useContext } from "react"
import Query from "../../Query"
import { QUERY_FEATURED_PODCASTS_BY_STATION_ID } from "../../../queries/page/podcast"
import { QUERY_STATION_BY_ID_NAME_ONLY } from "../../../queries/page/station"
import { TAB } from "../../../constants/breakpoints"
import StyledImage from "../../../components/StyledImage"
import * as St from "../../Styled"
import {
  MEDIA_TYPE_AUDIO,
  getConfigValue,
  DATA_TYPE_STATION_PODCAST,
  getEpisodeDayNumber,
} from "../../../constants/props"
import ReadMorePara from "../../ReadMorePara"
import styled from "styled-components"
import { AppContext } from "../../../contexts/AppContext"
import Carousel from "../../SliderContainer/carousel"
import { convertToSlug } from "../../../constants/props"
import { stationPodcastsShareURL } from "../../../constants/share"
import LikeAndShare from "../../LikeAndShare"
import useRecordUserData from "../../../hooks/useRecordUserData"
import moment from "moment-timezone"

const LikeShare = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  vertical-align: middle;
  position: unset;
  @media (min-width: ${TAB.minWidth}px) {
    position: relative;
  }
`

const FeaturedPodcast = ({ data }) => {
  const basePrefix = `${process.env.STRAPI_SERVER_URL}`
  const { updatePlayer, device, userState, ctxPlayer } = useContext(AppContext)
  const {
    id,
    title,
    uri,
    image,
    station = {},
    presenter,
    show,
    about,
    viewed,
    premium,
  } = data
  const recordUserData = useRecordUserData({
    type: DATA_TYPE_STATION_PODCAST,
    id: id,
    user: userState ? userState.id : null,
    viewed: viewed != null ? viewed : 0,
  })

  const stationNameSlug = convertToSlug(station.title)
  const stationID = station.id
  const countrySlug = station.country
    ? convertToSlug(station.country.title)
    : ""

  const podcastImage =
    image && image.url
      ? image.url.startsWith("http")
        ? image.url
        : basePrefix + image.url
      : ""

  const isPlaying =
    ctxPlayer.id === id &&
    ctxPlayer.dataType === DATA_TYPE_STATION_PODCAST &&
    ctxPlayer.playing
      ? true
      : false

  var presenterName = null
  var showDateTime = null

  if (show && show.episodes && show.episodes.length > 0) {
    // we have country timezone, podcast is associated with a show that has episodes
    // this means we can find out next episode for this podcast

    // in case no country timezone is found we will use utc as timezone
    let timeZone = "utc"
    if (station.country && station.country.Timezone)
      timeZone = station.country.Timezone

    let countryMoment = moment().tz(timeZone)

    // Read comments added in showsform.js when episode is added for shows for reason of using march 2020 dates
    let utcMoment = moment()
      .utc()
      .date(1) // 1st of march
      .month(2) // march
      .year(2020)
      .day(countryMoment.day())
      .hour(countryMoment.hour())
      .minute(countryMoment.minute())
      .second(0)
      .millisecond(0)

    // Episodes are in sorted order so we can just find a episode which is after utc moment (now)
    let nextEpisode = show.episodes.find(e =>
      moment(e.referenceDate).isAfter(utcMoment)
    )
    if (!nextEpisode) {
      // As we have not found any next episode when going in forward direction, the first episode (next week) would be first episode
      nextEpisode = show.episodes[0]
    }

    let day = getEpisodeDayNumber(nextEpisode.day)
    let nextShowMoment = moment().day(day)
    let fromTime = nextEpisode.from.split(":")
    if (fromTime.length === 2) {
      nextShowMoment.hour(fromTime[0]).minute(fromTime[1])
    }
    nextShowMoment.second(0).millisecond(0)
    if (nextShowMoment.isBefore(moment())) {
      nextShowMoment.add(1, "week")
    }
    showDateTime = nextShowMoment.format("dddd, Do MMMM, HH:mm")
    presenterName = show.presenter ? show.presenter.title : ""
  } else {
    // either there is no show or show don't have episodes, check for presenter associated with podcast if there is no show
    if (show) {
      presenterName = show.presenter ? show.presenter.title : ""
    } else {
      presenterName = presenter ? presenter.title : ""
    }
  }

  return (
    <St.PodcastFeaturedWrapper>
      <St.PodcastFeaturedImageWrapper>
        <StyledImage
          image={podcastImage}
          width={"100%"}
          height={"100%"}
          shaded={true}
          playing={isPlaying}
          premium={premium}
          imageAlt={title}
          borderRadius={
            getConfigValue(device, "box.podcasts.image.borderRadius") + "px"
          }
          onClickPlay={event => {
            event.preventDefault()
            event.stopPropagation()

            if (!isPlaying) {
              updatePlayer({
                type: MEDIA_TYPE_AUDIO,
                id,
                title,
                uri,
                preview: podcastImage,
                playlist: [],
                dataType: DATA_TYPE_STATION_PODCAST,
                playing: true,
                artist: null,
                station: {
                  id: stationID,
                  slug: stationNameSlug,
                },
              })

              if (userState && userState.isLoggedIn) {
                recordUserData()
              }
            } else {
              updatePlayer({
                ...ctxPlayer,
                playing: false,
              })
            }
          }}
        />
      </St.PodcastFeaturedImageWrapper>
      <St.PodcastFeaturedRightTextSection>
        <St.PodcastFeaturedTitle> {title} </St.PodcastFeaturedTitle>
        {presenterName && (
          <St.PodcastFeaturedSingleLineText>
            <div>Presenter:</div>
            <span>{presenterName}</span>
          </St.PodcastFeaturedSingleLineText>
        )}
        {showDateTime ? (
          <St.PodcastFeaturedSingleLineText>
            <div>Next Show:</div>
            <span>{showDateTime}</span>
          </St.PodcastFeaturedSingleLineText>
        ) : null}

        <St.PodcastFeaturedMultiLineText>
          <div>About:</div>
          <span>
            <ReadMorePara
              string={about}
              offset={getConfigValue(device, "box.podcasts.textOffset")}
            />
          </span>
        </St.PodcastFeaturedMultiLineText>

        <St.FeaturedSocialIconSection>
          <LikeShare>
            <LikeAndShare
              shareURL={stationPodcastsShareURL(
                stationID,
                convertToSlug(title),
                stationNameSlug,
                countrySlug,
                podcastImage
              )}
              likeType={DATA_TYPE_STATION_PODCAST}
              id={id}
              shareID={"featuredpodcasts_" + id}
              position={["top", "middle"]}
            />
          </LikeShare>
        </St.FeaturedSocialIconSection>
      </St.PodcastFeaturedRightTextSection>
    </St.PodcastFeaturedWrapper>
  )
}

const FeaturedBox = ({ podcasts, station }) => {
  // we can safely assume that there will be at least one podcast due to condition on parent element
  let settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    variableWidth: false,
    lazyLoad: false,
    arrows: false,
    autoplay: false,
    dots: true,
    autoplaySpeed: 2000,
    dotsClass: "carousel-dots",
  }

  return (
    <St.FeaturedBox>
      <St.FeaturedBoxContent>
        <St.FeaturedBoxHeading>
          FEATURED PODCAST : {station.title}
        </St.FeaturedBoxHeading>
        <Carousel settings={settings}>
          {podcasts.map((podcast, idx) => (
            <FeaturedPodcast
              key={podcast.id}
              data={{
                ...podcast,
              }}
            />
          ))}
        </Carousel>
      </St.FeaturedBoxContent>
    </St.FeaturedBox>
  )
}

// Fetches both station basic detail (station name) and featured podcasts that are needed to draw featured podcast box
// and then passed that information to next component to draw
const FeaturedPodcasts = ({ stationID }) => {
  return (
    <Query
      query={QUERY_STATION_BY_ID_NAME_ONLY}
      variables={{
        id: stationID,
      }}
      dataKey="stationData"
      fetchPolicy="network-only"
    >
      {({ data: { station }, fetchMore }) => {
        return (
          <Query
            query={QUERY_FEATURED_PODCASTS_BY_STATION_ID}
            variables={{
              id: stationID,
            }}
            fetchPolicy="network-only"
          >
            {({ data: { podcasts }, fetchMore }) => {
              return podcasts && podcasts.length ? (
                <FeaturedBox
                  station={station}
                  podcasts={podcasts}
                ></FeaturedBox>
              ) : null
            }}
          </Query>
        )
      }}
    </Query>
  )
}

export default FeaturedPodcasts
