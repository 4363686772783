import React from "react"
import FeaturedPodcasts from "./FeaturedPodcasts"
import AllPodcasts from "./AllPodcasts"
import * as St from "../Styled"

const ContentFeaturedPodcasts = props => {
  return (
    <St.ContentParent>
      <St.AdWrapper>
        <St.LeftAdSection />
        <St.LeftAd2Section />
      </St.AdWrapper>
      <St.ContentWrapper>
        <FeaturedPodcasts stationID={props.stationID} />
        <St.CenterContentSection>
          <AllPodcasts
            stationID={props.stationID}
            stationName={props.stationName}
          />
        </St.CenterContentSection>
        <St.BottomAdSection />
      </St.ContentWrapper>
      <St.AdWrapper>
        <St.RightAdSection />
        <St.RightAd2Section />
      </St.AdWrapper>
    </St.ContentParent>
  )
}

export default ContentFeaturedPodcasts
