import React, { useState } from "react"
import { useQuery } from "@apollo/client"
import * as St from "../../Styled"
import { QUERY_PODCASTS_BY_STATION_ID } from "../../../queries/page/podcast"
import PodcastCard from "./PodcastCard"

const AllPodcasts = props => {
  let [sortCriteria, setSortCriteria] = useState("title:asc")

  const onSortChange = event => {
    // FIND COUNTRY IN COUNTRIES ARRAY WITH SELECTED ID AND EXPAND IT TO CREATE NEW OBJECT AND SET AS NEW SELECTED COUNTRY
    setSortCriteria(event.target.value)
  }

  const { data, loading } = useQuery(QUERY_PODCASTS_BY_STATION_ID, {
    variables: {
      order: sortCriteria,
      stationID: props.stationID,
    },
    fetchPolicy: "network-only",
  })
  return !loading ? (
    <St.AllCardsSection>
      <St.AllCardsTopSection>
        <St.AllCardsHeading>
          STATION PODCAST : {props.stationName}
        </St.AllCardsHeading>
        <St.SortSpan>
          SORT :
          <St.SortSelect defaultValue={sortCriteria} onChange={onSortChange}>
            <St.SortOption value="title:asc">A-Z</St.SortOption>
            <St.SortOption value="title:desc">Z-A</St.SortOption>
          </St.SortSelect>
        </St.SortSpan>
      </St.AllCardsTopSection>
      <St.AllCardsWrapper>
        {data &&
          data.mediacontents.length &&
          data.mediacontents.map((podcast, i) => (
            <PodcastCard key={i} podcast={podcast} />
          ))}
      </St.AllCardsWrapper>
    </St.AllCardsSection>
  ) : null
}

export default AllPodcasts
