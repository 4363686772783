import React, { useContext } from "react"
import styled from "styled-components"
import StyledImage from "../../StyledImage"
import moment from "moment-timezone"
import {
  MEDIA_TYPE_AUDIO,
  getConfigValue,
  convertToSlug,
  DATA_TYPE_STATION_PODCAST,
  getEpisodeDayNumber,
} from "../../../constants/props"
import { stationPodcastsShareURL } from "../../../constants/share"
import { TAB } from "../../../constants/breakpoints"
import LikeAndShare from "../../LikeAndShare"
import { AppContext } from "../../../contexts/AppContext"
import * as St from "../../Styled"
import ReadMorePara from "../../ReadMorePara"
import useRecordUserData from "../../../hooks/useRecordUserData"

const LikeShare = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  vertical-align: middle;
  position: unset;
  @media (min-width: ${TAB.minWidth}px) {
    position: relative;
  }
`

const PodcastCard = ({ podcast }) => {
  const basePrefix = `${process.env.STRAPI_SERVER_URL}`
  const {
    updatePlayer,
    device,
    selectedCountry,
    userState,
    ctxPlayer,
  } = useContext(AppContext)
  const {
    id,
    uri,
    title,
    updatedAt,
    presenter,
    show,
    about,
    station = {},
    viewed,
    premium,
  } = podcast

  const recordUserData = useRecordUserData({
    type: DATA_TYPE_STATION_PODCAST,
    id: podcast ? podcast.id : null,
    user: userState ? userState.id : null,
    viewed: viewed != null ? viewed : 0,
  })

  const isPlaying =
    ctxPlayer.id === id &&
    ctxPlayer.dataType === DATA_TYPE_STATION_PODCAST &&
    ctxPlayer.playing
      ? true
      : false

  const stationNameSlug = convertToSlug(station.title)
  const stationID = station.id
  const countrySlug = station.country
    ? convertToSlug(station.country.title)
    : ""

  var presenterName = null
  var showDateTime = null

  if (show && show.episodes && show.episodes.length > 0) {
    // we have country timezone, podcast is associated with a show that has episodes
    // this means we can find out next episode for this podcast

    // in case no country timezone is found we will use utc as timezone
    let timeZone = "utc"
    if (station.country && station.country.Timezone)
      timeZone = station.country.Timezone

    let countryMoment = moment().tz(timeZone)

    // Read comments added in showsform.js when episode is added for shows for reason of using march 2020 dates
    let utcMoment = moment()
      .utc()
      .date(1) // 1st of march
      .month(2) // march
      .year(2020)
      .day(countryMoment.day())
      .hour(countryMoment.hour())
      .minute(countryMoment.minute())
      .second(0)
      .millisecond(0)

    // Episodes are in sorted order so we can just find a episode which is after utc moment (now)
    let nextEpisode = show.episodes.find(e =>
      moment(e.referenceDate).isAfter(utcMoment)
    )
    if (!nextEpisode) {
      // As we have not found any next episode when going in forward direction, the first episode (next week) would be first episode
      nextEpisode = show.episodes[0]
    }

    let day = getEpisodeDayNumber(nextEpisode.day)

    let nextShowMoment = moment().day(day)
    let fromTime = nextEpisode.from.split(":")
    if (fromTime.length === 2) {
      nextShowMoment.hour(fromTime[0]).minute(fromTime[1])
    }
    nextShowMoment.second(0).millisecond(0)

    /*
    It is possible that this next show moment is already passed in certain cases, in that cade we need to increment date by 1 week
    */

    if (nextShowMoment.isBefore(moment())) {
      nextShowMoment.add(1, "week")
    }

    showDateTime = nextShowMoment.format("dddd, Do MMMM, HH:mm")
    presenterName = show.presenter ? show.presenter.title : ""
  } else {
    // either there is no show or show don't have episodes, check for presenter associated with podcast if there is no show
    if (show) {
      presenterName = show.presenter ? show.presenter.title : ""
    } else {
      presenterName = presenter ? presenter.title : ""
    }
  }

  const image =
    podcast.image && podcast.image.url
      ? podcast.image.url.startsWith("http")
        ? podcast.image.url
        : basePrefix + podcast.image.url
      : ""
  return (
    <St.PodcastListWrapper>
      <St.PodcastWrapper>
        <St.PodcastListImageWrapper>
          <StyledImage
            image={image}
            width={"100%"}
            height={"100%"}
            shaded={true}
            playing={isPlaying}
            premium={premium}
            imageAlt={title}
            borderRadius={
              getConfigValue(device, "podcasts.list.image.borderRadius") + "px"
            }
            onClickPlay={event => {
              event.preventDefault()
              event.stopPropagation()

              if (!isPlaying) {
                updatePlayer({
                  type: MEDIA_TYPE_AUDIO,
                  id,
                  title,
                  uri,
                  preview:
                    podcast.image && podcast.image.url
                      ? podcast.image.url.startsWith("http")
                        ? podcast.image.url
                        : basePrefix + podcast.image.url
                      : "",
                  playlist: [],
                  dataType: DATA_TYPE_STATION_PODCAST,
                  playing: true,
                  artist: null,
                  station: {
                    id: stationID,
                    slug: stationNameSlug,
                  },
                })

                if (userState && userState.isLoggedIn) {
                  recordUserData()
                }
              } else {
                updatePlayer({
                  ...ctxPlayer,
                  playing: false,
                })
              }
            }}
          />
        </St.PodcastListImageWrapper>
        <St.PodcastListRightTextSection>
          <St.PodcastInfoWrapper>
            <St.PodcastTitleWrapper>
              <St.PodcastTitle>{title}</St.PodcastTitle>
              <St.PodcastDate>
                {moment(updatedAt).format("DD MMMM YYYY")}
              </St.PodcastDate>
            </St.PodcastTitleWrapper>
            {presenterName ? (
              <St.PodcastListSingleLineText>
                <div>Presenter: </div> <span>{presenterName}</span>
              </St.PodcastListSingleLineText>
            ) : null}
            {showDateTime ? (
              <St.PodcastListSingleLineText>
                <div>Next Show: </div> <span> {showDateTime}</span>
              </St.PodcastListSingleLineText>
            ) : null}
            {podcast.about && (
              <St.PodcastListMultiLineText>
                <div>About:</div>
                <ReadMorePara
                  string={about}
                  offset={getConfigValue(device, "podcasts.list.textOffset")}
                />
              </St.PodcastListMultiLineText>
            )}
          </St.PodcastInfoWrapper>
          <St.PodcastLikeShareSection>
            <LikeShare>
              <LikeAndShare
                shareURL={
                  selectedCountry && selectedCountry.id
                    ? stationPodcastsShareURL(
                        stationID,
                        convertToSlug(title),
                        stationNameSlug,
                        countrySlug,
                        image
                      )
                    : null
                }
                id={id}
                shareID={"allpodcasts_" + id}
                likeType={DATA_TYPE_STATION_PODCAST}
              />
            </LikeShare>
          </St.PodcastLikeShareSection>
        </St.PodcastListRightTextSection>
      </St.PodcastWrapper>
    </St.PodcastListWrapper>
  )
}

export default PodcastCard
