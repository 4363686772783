import gql from "graphql-tag"
// Desc: Gives Station Data on providing ID
export const QUERY_PODCAST_BY_ID = gql`
  query PodcastById($id: ID!) {
    mediacontent(id: $id) {
      id
      title
      uri
      contenttype
      viewed
      image {
        id
        url
      }
      artist {
        id
        title
      }
      presenter {
        id
        title
      }
      station {
        id
        title
      }
      show {
        id
        title
      }
      premium
      updatedAt
      about
    }
  }
`

export const QUERY_PODCASTS_BY_STATION_ID = gql`
  query PodcastsByStationId(
    $offset: Int
    $max: Int
    $stationID: ID!
    $order: String
  ) {
    mediacontents(
      start: $offset
      limit: $max
      sort: $order
      where: { contenttype: "audio", station: $stationID }
    ) {
      id
      title
      uri
      viewed
      image {
        id
        url
      }
      presenter {
        id
        title
      }
      show {
        id
        title
        episodes(sort: "referenceDate:asc") {
          id
          from
          to
          day
          referenceDate
        }
        presenter {
          id
          title
        }
      }
      station {
        id
        title
        country {
          id
          title
          Timezone
        }
      }
      premium
      updatedAt
      about
    }
  }
`

export const QUERY_FEATURED_PODCASTS_BY_STATION_ID = gql`
  query FeaturedPodcastsByStationId(
    $id: ID!
    $offset: Int
    $max: Int
    $order: String
  ) {
    podcasts: mediacontents(
      start: $offset
      limit: $max
      sort: $order
      where: { station: $id, contenttype: "audio", featured: true }
    ) {
      id
      title
      uri
      contenttype
      viewed
      image {
        id
        url
      }
      presenter {
        id
        title
      }
      show {
        id
        title
        episodes(sort: "referenceDate:asc") {
          id
          from
          to
          day
          referenceDate
        }
        presenter {
          id
          title
        }
      }
      station {
        id
        title
        country {
          id
          title
          Timezone
        }
      }
      premium
      updatedAt
      about
    }
  }
`
export const QUERY_PODCASTS_BY_COUNTRY_ID = gql`
  query PodcastsByStationId($offset: Int, $max: Int, $order: String) {
    mediacontents(
      start: $offset
      limit: $max
      sort: $order
      where: { contenttype: "audio" }
    ) {
      id
      title
      uri
      contenttype
      viewed
      image {
        id
        url
      }
      artist {
        id
        title
      }
      presenter {
        id
        title
      }
      station {
        id
        title
      }
      premium
      updatedAt
      about
    }
  }
`
export const QUERY_PODCASTS_BY_ARTIST_ID = gql`
  query PodcastsByArtistID(
    $offset: Int
    $max: Int
    $artistID: ID!
    $order: String
  ) {
    mediacontents(
      start: $offset
      limit: $max
      sort: $order
      where: { contenttype: "audio", artist: $artistID }
    ) {
      id
      title
      uri
      viewed
      image {
        id
        url
      }
      artist {
        id
        title
        country {
          id
          title
        }
      }
      premium
      updatedAt
      about
    }
  }
`

export const QUERY_FEATURED_PODCASTS_BY_ARTIST_ID = gql`
  query FeaturedPodcastsByArtistID(
    $artistID: ID!
    $offset: Int
    $max: Int
    $order: String
  ) {
    podcasts: mediacontents(
      start: $offset
      limit: $max
      sort: $order
      where: { artist: $artistID, contenttype: "audio", featured: true }
    ) {
      id
      title
      uri
      viewed
      image {
        id
        url
      }
      artist {
        id
        title
        country {
          id
          title
        }
      }
      premium
      updatedAt
      about
    }
  }
`
